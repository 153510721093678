// Styling for the post page
.post-preview {
    >a {
//         color: $gray-900;

        &:focus,
        &:hover {
//             text-decoration: none;
			text-decoration: underline;
//             color: $secondary;
        }

        >.post-title {
            font-size: 30px;
            margin-top: 6px;
            margin-bottom: 16px;
        }

        >.post-subtitle {
			font-weight: 400;
//             margin: 0 0 10px;
			margin: 0;
        }
    }

    >.post-meta {
        font-size: 16px;
//         font-style: italic;
        margin-top: 0;
        margin-bottom: 0;
//         color: $gray-600;
//         font-family: "Tiempos-Text-Regular";
//         font-family: "Tiempos-Text-Italics";
			font-family: "FoundersGroteskText-Regular";

        >a {
            text-decoration: none;
            color: $gray-900;

            &:focus,
            &:hover {
                text-decoration: underline;
                color: $primary;
            }
        }
    }

    >.read-time {
		margin-bottom: 70px;
	}

    @media only screen and (min-width: 768px) {
        >a {
            >.post-title {
//                 font-size: 36px;
				font-size: 30px;
            }
        }
    }
}