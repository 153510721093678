// Styling for the footer
footer {
    padding: 50px 0 65px;
    padding-bottom: 0;
    padding-top: 10px;

    .list-inline {
//         margin: 0;
//         padding: 0;
		display: inline-block;
		float: right;
    }

    .copyright {
        margin-bottom: 0;
        margin-top: 0;
        text-align: left;
		font-family: 'FoundersGroteskText-Regular';
		font-size: 15px;
		display: inline-block;
    }
}

@media only screen and (max-width: 400px) {
	.copyright {
		width: 100%;
		text-align: center!important;
	}
	.list-inline {
		float: none;
		width: 100%;
		text-align: center!important;
	}
}
