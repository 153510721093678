// Global styling for this template
// * { -webkit-font-smoothing: antialiased; }

body {
    font-size: 17px;
//     font-weight: 400;
}

p {
    line-height: 1.5;
    margin: 30px 0;

    a {
        text-decoration: underline;
    }
}

.italic {
	font-family: 'Tiempos-Text-Italics';
}

/*
.bold {
	font-weight: bold;
}
*/

p.opening { 
	margin-bottom: 70px; 
	margin-top: 15px;
	font-family: "Tiempos-Text-Regular";
	font-size: 19px;
}

p.page-opening {
	font-family: "Tiempos-Text-Regular";
	margin-top: 35px;
	margin-bottom: 35px;
	font-size: 17px;
}

.post-title {
	font-size: 30px;
	font-weight: normal;
}

p.post-subtitle {
	font-family: "Tiempos-Text-Regular";
	line-height: 120%;
	margin: 0;
	margin-bottom: 10px;
	font-size: 17px;
}

.article-border {
	display: inline-block;
	position: relative;
	width: 25%;
	border-top: 1px solid rgba(#dc3545, 0.75);
}

.about-img {
	margin-top: 20px;
}

.img-404 {
	margin-top: 20px;
}

.img-caption {
	font-family: 'FoundersGroteskText-Regular';
	font-size: 16px;
	margin:0;
	margin-bottom: 30px;
}

.post-img {
	margin-top: 15px;
}

.post-img-fix {
	margin-bottom: 35px;
}

.list-inline li a {
	font-family: 'FoundersGroteskText-Regular';
	font-size: 15px;
}

.email-inputs {
	display: block;
	width: 100%;
	text-align: center;
	margin-bottom: 50px;
	margin-left: -20px;
}

#tlemail {
	padding-bottom: 2px;
}

.submit-button {
	background-color: white;
	border: none;
	border: 1px solid rgba(1, 1, 1, 0.5);
}

.follow-ul {
	margin-top: 30px;
}

li {
	margin-bottom: 15px;
}

li form {
	margin-top: 35px;
	margin-bottom: 35px;
}

h1.posts-heading {
	font-family: "FoundersGroteskX-Condensed-Semibold";
	font-size: 45px;
}

h1.post-margin {
	margin-bottom: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

a {
		color: black;

    &:focus,
    &:hover {
		color:black;
    }
}

blockquote {
    font-style: italic;
    color: $gray-600;
    margin-bottom: 0;
}

cite {
	color: $gray-600;
	width: 100%;
	display: inline-block;
	text-align: right;
}

.section-heading {
    font-size: 28px;
}

.caption {
    font-size: 14px;
    font-style: italic;
    display: block;
    margin: 0;
    padding: 10px;
    text-align: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

img::selection {
    color: $white;
    background: transparent;
}

img::-moz-selection {
    color: $white;
    background: transparent;
}